.or-divider {
    position: relative;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    &-line {
        height: 1px;
        background-color: #D7D7D7;
    }

    &-text {
        position: absolute;
        width: fit-content;
        top: 50%;
        left: 50%;
        margin: 0;
        transform: translate(-50%, -50%);
        padding: 0 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #FFFFFF;
        background: #142437;
    }
}