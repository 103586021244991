.description {
    margin-bottom: 8px;
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
}

.illustration {
    max-width: 100%;
    height: auto;
}