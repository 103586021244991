.set-request {
    :global(.row) {
        margin-bottom: 46px;

        &:nth-child(1) {
            margin-bottom: 24px;
        }
    }
}

.error-text {
    bottom: -30px;
}

.tx-json {
    width: 100%;
    height: 200px;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color: #F6F7FB;
    background-color: #1E344D;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    overflow-y: auto;
    word-break: break-all;
}