@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: rgba(rgb(31, 28, 28), 0.9);
    color: white;
    z-index: 999;

    &-image {
        margin-bottom: 30px;
        animation: spin 10s linear infinite;
    }

    &-text {
        margin-top: 30px;
    }
}