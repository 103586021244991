.button {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    cursor: pointer;
    white-space: nowrap;

    &:focus {
        outline: none;
    }

    &-primary {
        color: #FFFFFF;
        background: #00A3FF;
        border: 1px solid #00A3FF;

        &:hover {
            background-color: lighten(#00A3FF, 5%);
        }
    }

    &-outline-primary {
        color: #00A3FF;
        background: #FFFFFF;
        border: 1px solid #00A3FF;

        &:hover {
            color: #FFFFFF;
            background: #00A3FF;
        }
    }

    &-secondary {
        color: #FFFFFF;
        background: #123F75;
        border: 1px solid #123F75;

        &:hover {
            background-color: lighten(#123F75, 5%);
        }
    }

    &-outline-secondary {
        color: #123F75;
        background: #FFFFFF;
        border: 1px solid #123F75;

        &:hover {
            color: #FFFFFF;
            background-color: #123F75;
        }
    }

    &-group-item {
        margin: 0 0 24px 0;

        @media (min-width: 375px) {
            margin: 0 12px 0 0;
        }
    }

    &-outline-success {
        color: #181818;
        background: #FFFFFF;
        border: 1px solid #22B59A;

        &:hover {
            color: #FFFFFF;
            background: #22B59A;
        }
    }

   &-lg {
        display: flex;
        margin-bottom: 15px;
        border-radius: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }

    &-sm {
        display: inline-flex;
        padding: 8px 10px;
        border-radius: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }

    &-fit-content {
        width: fit-content;
    }
}