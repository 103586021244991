.side-bar {
    flex-shrink: 0;
    background-color: #1E344D;
    width: 100%;

    @media (min-width: 992px) {
        width: initial;
    }
}

.menu {
    padding-right: 20px;
    &-item {
        &-self {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
        }


        &-icon {
            position: relative;
            top: -2px;
            display: inline-block;
            padding-left: 16px;
            padding-right: 16px;
        }

        &-text {
            display: inline-block;
            color: #F6F7FB;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;

            &-active {
                color: #FFFFFF;
            }
        }

        &-expanded {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            margin-left: 16px;
            margin-right: 16px;
            border-radius: 100%;

            &-true {
                background-color: #1A87FF;
            }

            &-false {
                background-color: #213B58;
            }

            &-icon {
                box-sizing: content-box;
                width: 8px;
                height: auto;
                color: #FFFFFF;
            }

            cursor: pointer;
        }
    }
}

.sub-menu {
    &-item {
        padding-left: 52px;
        padding-right: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        color: #FFFFFF;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;

        &-active {
            background-color: #213B58;
            border-right: 4px solid #1A87FF;
        }
    }
}


.logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    background-color: #192B40;
    cursor: pointer;

    &-icon {
        display: inline-block;
        margin-left: 16px;
        margin-right: 12px;
    }

    &-text {
        color: #FFFFFF;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
    }
}